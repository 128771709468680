import React from 'react'
import './footer.css'
import { BsLinkedin } from 'react-icons/bs'
import { BsGithub } from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <ul className='footer__socials'>
        <a href="https://www.linkedin.com/in/mitja-immonen/" target="_blank" rel='noreferrer'><BsLinkedin/></a>
        <a href="https://github.com/mitjaimmonen" target="_blank" rel='noreferrer'><BsGithub/></a>
      </ul>

      <div className="footer__copyright">
        <small>&copy; Mitja Immonen</small>
      </div>
    </footer>
  )
}

export default Footer