import React from 'react'
import './header.css'
import ME from '../../assets/me2.jpg'
import CTA from './CTA'


const Header = () => {
  return (
    <header>
      <div id='home' className="container header__container">
        <div className="header__container-top">
          <h1>Mitja Immonen</h1>
          <h4 className="text-light">Software Developer</h4>
        </div>
      </div>

      <div className="header-separator"></div>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">

          <h4>Hi there!</h4>
          <p>
            My name is Mitja, I'm a tech nerd with a bit of an artistic background.
            Currently I am employed as a Mobile Developer working with Unity and some Flutter in the horizon.
          </p>
          <p>
            Back when studying interactive media at Esedu and then at Tamk UAC, I accumulated a few years of work experience in visual design and media production.
            Later in my studies I focused on emerging media and games programming.
            In 2020 I graduated and got a fulltime software development job and since then I have been a software developer by profession.
          </p>
          <p>
            On my spare time I work on personal projects, like apps and games, and try to learn new things.
            The recent tools I have been using include Godot, Unity, Flutter, Vue, TypeScript, ChatGPT and Adobe software.
            One day I hope to become a self sufficient software developer.
          </p>
          <CTA />

        </div>
      </div>
    </header>
  )
}

export default Header