import React from 'react'
import Header from './components/header/Header'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'

const App = () => {
  return (
    <div className='root'>
      <Header />
      <Contact />
      <Footer />
    </div>
  )
}

export default App