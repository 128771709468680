import React from 'react'
import './contact.css'
import { MdEmail } from 'react-icons/md'
import { FaTelegramPlane } from 'react-icons/fa'

const Contact = () => {
  return (
    <section id='contact'>
      <h2>Contact</h2>
      <div className="header-separator"></div>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>contact@mitja.fi</h5>
            <a href="mailto:contact@mitja.fi" rel='noreferrer' target='_blank'>Send an email</a>
          </article>
          <article className='contact__option'>
            <FaTelegramPlane className='contact__option-icon' />
            <h4>Telegram</h4>
            <h5>Mitja Immonen</h5>
            <a href="https://t.me/mitjaimmonen" rel='noreferrer' target='_blank'>Send a message</a>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Contact